exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artikelen-js": () => import("./../../../src/pages/artikelen.js" /* webpackChunkName: "component---src-pages-artikelen-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recepten-js": () => import("./../../../src/pages/recepten.js" /* webpackChunkName: "component---src-pages-recepten-js" */),
  "component---src-pages-specialisaties-js": () => import("./../../../src/pages/specialisaties.js" /* webpackChunkName: "component---src-pages-specialisaties-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */)
}

